/**
 * 权限管理
 */

import service from "../service.js";

export const getPermissions = roleId => {
    return service({
      method: 'GET',
      url: '/permission/getPermissions',
      params: {
        roleId
      }
    })
}
export const savePermissions = (roleId, data) => {
    return service({
      method: 'POST',
      headers:{
        "Content-type":"application/json"
      },
      url: '/permission/savePermission?roleId='+roleId,
      data
    })
}
