<template>
    <div class='perIndex'>
    <el-card class="box-card">
      <div class="df">
        <div class="box1 box">
          <div class="title">
            角色信息
          </div>
          <div class="info">
            <el-tree
              ref="roleTree"
              :data="roleList"
              :props="defaultRoleProps"
              node-key="roleId"
              :current-node-key="defaultNodeKey"
              @node-click="handleRoleNode"
              highlight-current
            ></el-tree>
          </div>
        </div>
        <div class="box box2">
          <div class="title df jcsb">
            <span>权限信息</span>
          </div>
          <div class="info">
            <el-tree
              ref="perTree"
              :data="perList"
              show-checkbox
              default-expand-all
              node-key="id"
              :default-checked-keys="defaultCheckedArr"
              :props="defaultPerProps"
              
            >
            </el-tree>
          </div>
        </div>
      </div>
      <div>
           <el-button
              class="btn"
              :disabled="saveFlag"
              @click="handleSave"
            >提交</el-button>
      </div>
    </el-card>

  </div>
</template>
<script>
import { getRoles } from '@/api/role'
import { getPermissions, savePermissions } from '@/api/permission'
export default {
    data() {
        return {
            saveFlag: false,
            roleList: [],
            defaultRoleProps: {
                children: 'children',
                label: 'roleName'
            },
            defaultNodeKey: '',
            perList: [], // 权限列表
            defaultCheckedArr: [], // 选中权限列表
            defaultPerProps: {
                children: 'functionVoList',
                label: 'label'
            }
        };
    },
    mounted(){
        this.findRoles()
    },
    methods: {

        findRoles(){
            getRoles().then(res =>{
                if(res.code === '000000'){
                    this.roleList = res.data
                    this.defaultNodeKey = res.data[0].roleId
                    this.$nextTick(() => {
                        this.$refs.roleTree.setCurrentKey(this.defaultNodeKey)
                    })
                    this.loadPerInfo(res.data[0].roleId)
                }
            })
        },
         // 点击角色树
        handleRoleNode (data) {
        this.loadPerInfo(data.roleId)
        },

         // 加载权限信息
        loadPerInfo (id) {
            getPermissions(id).then(res => {
                if (res.code === '000000') {
                const list = res.data
                const defaultChecked = []
                list.forEach(item => {
                    item.label = item.moduleName
                    item.id = item.moduleId
                    item.functionVoList.forEach(ele => {
                    ele.label = ele.funcName
                    ele.id = ele.funcId
                    if (ele.accessId) defaultChecked.push(ele.funcId)
                    })
                })
                this.perList = list
                this.defaultCheckedArr = defaultChecked
                }
            })
        },
        // 修改权限
        handleSave () {
            if (!this.saveFlag) {
                this.saveFlag = true
                let checkedList = this.$refs.perTree.getCheckedNodes()
                checkedList = checkedList.filter(ele => ele.funcId)
                const funcIds = checkedList.map(item => item.funcId)
                const roleId = this.$refs.roleTree.getCurrentKey()
                savePermissions(roleId,funcIds).then(res => {
                if (res.code === '000000') {
                    this.$message.success('修改成功')
                }
                this.saveFlag = false
                }).catch(() => {
                this.saveFlag = false
                })
            }
        }

    }
}
</script>

<style lang="scss">
    .perIndex .el-tree-node.is-current {
      color: #3270ED;
    }
    .perIndex .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
      background: none;
    }
    </style>
    <style lang='scss' scoped>
    .save {
      padding: 8px 12px;
    }
    .df {
      display: flex;
      padding-right: 12px;
      margin-bottom: 20px;
    }
    .jcsb {
      justify-content: space-between;
    }
    .box{
      border: 1px solid #E5E6EB;
      border-radius: 4px;
      .title{
        height: 40px;
        line-height: 40px;
        background-color: #F7F8FA;
        padding-left: 19px;
        font-size: 14px;
        color: #1D2129;
      }
      .info{
        height: 538px;
        overflow-y: scroll;
      }
    }
    .box1{
      width: 30%;
      margin-right: 20px;
    }
    .box2{
      width: 60%;
        border: 1px solid #E5E6EB;
      border-radius: 4px;
    }
</style>
